<template>
  <section id="dashboard-ecommerce">
    <DxGrid
      :data-source="dataSource"
      :canAdd="false"
      @onToggleView="(v) => onToggleView(v)"
      @on-delete="(v) => onDelete(v)"
      @on-update="(v) => onUpdate(v)"
      @on-update-cancel="(v) => loadServiceRequest()"
      @on-row-click="(v) => onViewDetail(v)"
    >
      <template #columns>
        <DxColumn
          v-for="(item, index) in dataColumns"
          :key="index"
          :data-field="item.field"
          :caption="item.caption"
        />
      </template>
    </DxGrid>
  </section>
</template>

<script>
import jobRequestApi from '@api/jobrequest_headers';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanyServiceRequestComponent',
  props: {
    headerId: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    dataColumns: [
      { caption: 'Ticket No.', field: 'ticket_no' },
      { caption: 'Customer', field: 'company_name' },
      { caption: 'Priority', field: 'urgency_level' },
      { caption: 'Problem Description', field: 'description' },
      { caption: 'Reported Date', field: 'preview_report_date' },
      { caption: 'Status', field: 'job_status' },
      { caption: 'Assigned', field: 'assigned_user' },
    ],
  }),
  methods: {
    loadServiceRequest() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `company_id=${this.headerId}`,
      };

      jobRequestApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            for (const item of data) {
              const { problem_description, reported_date } = item;

              item.preview_report_date =
                this.displayDateTimeFromUnix(reported_date);

              item.description = `${problem_description.substring(0, 45)}...`;
            }

            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        //
      });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      if (this.isTogglingView) {
        return;
      }

      const url = `/pages/services-tools/service-request/view?id=${event.key}`;
      window.open(url, '_blank');
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      //
    },
    onPrintPDF(event) {
      const data = event?.row?.data;

      if (data) {
      }
    },
  },
};
</script>

<style></style>
