<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h2>Customer Manage</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Information" active>
              <!-- <b-row>
                <b-col class="text-right">
                  <b-button
                    title="InSight Panel"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="openSection = !openSection"
                  >
                    <feather-icon
                      style="width: 25px; height: 25px"
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col class="text-right">
                  <b-button
                    type="button"
                    class="mr-1"
                    variant="outline-danger"
                    @click="goBack()"
                  >
                    Back
                  </b-button>
                  <b-button type="button" variant="success" @click="onEdit()">
                    Edit
                  </b-button>
                </b-col>
              </b-row>

              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12" md="3" v-if="formData.logo">
                    <b-row>
                      <b-col class="text-center">
                        <b-form-group>
                          <b-img
                            style="margin-bottom: 5px"
                            height="250"
                            width="250"
                            thumbnail
                            fluid
                            :src="formData.image_url"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" :md="formData.logo ? '9' : '12'">
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Company ID"
                          label-for="v-company_id"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.company_id"
                            id="v-company_id"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Company Type"
                          label-for="v-company_type"
                        >
                          <v-select
                            disabled
                            id="v-company_type"
                            v-model.number="formData.company_type"
                            :options="companyTypes"
                            :reduce="(companyTypes) => companyTypes.name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="Active / Archived" label-for="v-status">
                          <b-form-select
                            disabled
                            v-model="formData.company_status"
                            :options="statusOptions"
                            id="v-status"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Company Name"
                          label-for="v-company_name"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.company_name"
                            id="v-company_name"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Registration Code"
                          label-for="v-registration_code"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.registration_code"
                            id="v-registration_code"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="GST Registration Code"
                          label-for="v-gst_registration_code"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.gst_registration_code"
                            id="v-gst_registration_code"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Location</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Building/Block/House No. and Street Name"
                      label-for="v-addr1"
                    >
                      <b-form-input
                        disabled
                        v-model="formData.addr1"
                        id="v-addr1"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Unit No. and Building Name"
                      label-for="v-addr2"
                    >
                      <b-form-input
                        disabled
                        v-model="formData.addr2"
                        id="v-addr2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="City" label-for="v-city">
                      <b-form-input
                        disabled
                        v-model="formData.city"
                        id="v-city"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="State" label-for="v-state">
                      <b-form-input
                        disabled
                        v-model="formData.state"
                        id="v-state"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Region" label-for="v-region">
                      <b-form-input
                        disabled
                        v-model="formData.region"
                        id="v-region"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="Country" label-for="v-country">
                      <b-form-input
                        disabled
                        v-model="formData.country"
                        id="v-country"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Postal" label-for="v-postal">
                      <b-form-input
                        disabled
                        v-model="formData.postal"
                        id="v-postal"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Other Information</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Phone 1" label-for="v-telephone_1">
                      <b-form-input
                        disabled
                        v-model="formData.telephone_1"
                        id="v-telephone_1"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Phone 2" label-for="v-telephone_2">
                      <b-form-input
                        disabled
                        v-model="formData.telephone_2"
                        id="v-telephone_2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Website" label-for="v-website">
                      <b-form-input
                        disabled
                        v-model="formData.website"
                        id="v-website"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Email" label-for="v-email">
                      <b-form-input
                        disabled
                        v-model="formData.email"
                        id="v-email"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Currency" label-for="v-currency_id">
                      <b-form-input
                        disabled
                        v-model="formData.currency_name"
                        id="v-currency_id"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="GST" label-for="v-gst">
                      <b-form-input
                        disabled
                        v-model="formData.gst"
                        id="v-gst"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Company Locations</h2>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group>
                      <CompanyLocations
                        :isDisabled="true"
                        :data-source="dataSourceCompanyLocations"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Company Contacts</h2>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group>
                      <CompanyContacts
                        :isDisabled="true"
                        :data-source="dataSourceCompanyContacts"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Internal Notes</h2>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group>
                      <quill-editor
                        disabled
                        id="editor"
                        v-model="formData.notes"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Notes">
              <Notes
                ref="notesRef"
                module="company"
                :header-id="parseInt(formData.id)"
              />
            </b-tab>
            <b-tab title="Documents">
              <Documents
                ref="documentsRef"
                module="company"
                :header-id="parseInt(formData.id)"
              />
            </b-tab>
            <b-tab title="Service Requests">
              <CompanyServiceRequest
                ref="companyServiceRequestsRef"
                :header-id="parseInt(formData.id)"
              />
            </b-tab>
            <b-tab title="Logs">
              <Logs
                ref="logsRef"
                module="company"
                :header-id="parseInt(formData.id)"
              />
            </b-tab>
            <b-tab title="Comments">
              <Comments
                ref="commentsRef"
                module="company"
                :header-id="parseInt(formData.id)"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-row>
          <b-col>
            <CompanyInsightPanel
              :header-id="parseInt(formData.id)"
              @close-panel="(v) => (openSection = v)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-tabs v-model="activePanelTab" fill>
                <b-tab active>
                  <template #title>
                    <small>Comments</small>
                  </template>
                  <CompanyCommentsPanel
                    :header-id="parseInt(formData.id)"
                    :is-active="activePanelTab"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Logs</small>
                  </template>
                  <CompanyLogsPanel
                    :header-id="parseInt(formData.id)"
                    :is-active="activePanelTab"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Approval</small>
                  </template>
                  <CompanyApproval
                    :header-id="parseInt(formData.id)"
                    :is-active="activePanelTab"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import companiesApi from '@api/companies';
import statusesApi from '@api/statuses';
import companyTypesApi from '@api/company_types';
import { queryParameters } from '@/schema';

import CompanyLocations from './components/CompanyLocations';
import CompanyContacts from './components/CompanyContacts';
import CompanyInsightPanel from './components/CompanyInsightPanel';
import CompanyDocuments from './components/CompanyDocuments';
import CompanyNotes from './components/CompanyNotes';
import CompanyLogs from './components/CompanyLogs';
import CompanyComments from './components/CompanyComments';
import CompanyCommentsPanel from './components/CompanyCommentsPanel';
import CompanyLogsPanel from './components/CompanyLogsPanel';
import CompanyApproval from './components/CompanyApproval';
import CompanyServiceRequest from './components/CompanyServiceRequest';

export default {
  name: 'CustomersViewPage',
  components: {
    CompanyLocations,
    CompanyContacts,
    CompanyInsightPanel,
    CompanyDocuments,
    CompanyNotes,
    CompanyLogs,
    CompanyComments,
    CompanyCommentsPanel,
    CompanyLogsPanel,
    CompanyApproval,
    CompanyServiceRequest,
  },
  data: () => ({
    activeTab: 0,
    activePanelTab: 0,
    openSection: false,
    dataSourceCompanyLocations: [],
    dataSourceCompanyContacts: [],
    statuses: [],
    statusOptions: [],
    companyTypes: [],
    formData: {
      id: 0,
      company_id: null,
      company_name: null,
      company_type: null,
      company_status: null,
      attn: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      tel: null,
      fax: null,
      website: null,
      email: null,
      logo: null,
      currency_id: null,
      registration_code: null,
      gst: null,
      employee_id: 0,

      filename: null,
      base64: null,
    },
  }),
  watch: {
    activeTab(index) {
      if (index === 1) {
        this.$refs.notesRef.loadNotes();
      }

      if (index === 2) {
        this.$refs.documentsRef.loadDocuments();
      }

      if (index === 3) {
        this.$refs.companyServiceRequestsRef.loadServiceRequest();
      }

      if (index === 4) {
        this.$refs.logsRef.loadLogs();
      }

      if (index === 5) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
  },
  mounted() {
    this.loadCompanyTypes();
    this.loadSystemCode();
    if (this.$route.query.id) {
      this.loadCompany();
    }
  },
  methods: {
    loadCompany() {
      companiesApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const { company_locations } = data;

          if (company_locations.data) {
            company_locations.data.forEach((location) => {
              location.address = location.addr1;

              if (location.addr2) {
                location.address += ` ${location.addr2}`;
              }

              if (location.country) {
                location.address += ` ${location.country}`;
              }

              if (location.postal) {
                location.address += ` ${location.postal}`;
              }
            });

            this.dataSourceCompanyLocations = company_locations.data;
          }

          if (data.company_contacts.data) {
            this.dataSourceCompanyContacts = data.company_contacts.data;
          }

          data.image_url = `${process.env.VUE_APP_ROOT_STATIC_URL}/company/${data.id}/${data.logo}`;
          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      statusesApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];

            for (const item of data) {
              const { code: value, name: text, selections } = item;
              items.push({ value, text, selections });
            }

            this.statuses = items;

            const coystatus = items.find((x) => {
              return x.value === 'coystatus';
            });
            if (coystatus) {
              const { selections } = coystatus;
              this.onSelectStatus(selections);
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCompanyTypes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      companyTypesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({
              name: item.name,
              label: `${item.name} - ${item.description}`,
            });
          }

          this.companyTypes = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      const url = `/pages/masterlists/customers/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    goBack() {
      this.$router.push('/pages/masterlists/customers');
    },
    onRedirectServiceRequest() {
      const url = `/pages/services-tools/service-request?company=${this.formData.company_id}`;
      window.open(url, '_blank');
    },
    onSelectStatus(data) {
      if (data) {
        this.statusOptions = data;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
